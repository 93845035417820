import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Menu from '../components/menu';
import Layout from "../components/layout";

export default () => {
  return (
    <Layout>
      <div className="jumbotron">
        <Link to="/" className="closeX"><span>X</span></Link>
        <Menu localPath="/extras" />
        <h1>Software, Spiele und...</h1>
      </div>
      <div className="gridBox">
        <div className="gridFraction"> 
          <Link to="/videos"><img src="/images/film-ob-image.png" /></Link>          
        </div>
        <div className="gridFraction">
          <div>
            Hier können Sie sich einige <Link to="/videos">Videos</Link> anschauen, die im Umfang der OER entstanden sind.
          </div>
        </div>
        <div className="gridFraction">
          <Link to="/software"><img src="/images/softwareImage.png" /></Link> 
        </div>
        <div className="gridFraction">
          <div>
            Hier finden Sie <Link to="/software">Software</Link>, die für einige OpenBooks programmiert worden ist.
          </div>
        </div>
        <div className="gridFraction">
          <Link to="https://musikanalyse.net/materialien/"><img src="/images/klangundakkord.png" /></Link> 
        </div>
        <div className="gridFraction">
          <div>
            Arbeitsbögen, die bisher auf dieser Website bereitgestellt worden sind, wurden mit den Arbeitsbögen auf <i>musikanalyse.net</i> zusammengeführt. Sie können sich diese Materialien <a href="https://musikanalyse.net/materialien/">hier</a> herunterladen. 
          </div>
        </div>
      </div>
      <Helmet>
        <title>OER | Ulrich Kaiser - Feedback</title>
        <meta name="description" content="Hier finden Sie eine Übersicht zu den Extras, die im Zusammenhang mit den OpenBooks (OER) von Ulrich Kaiser veröffentlicht worden sind." />
        <meta name="keywords" content="Extras, Übersicht, OER, Open Educational Resources, OpenBooks" />
        <meta name="copyright" content="Ulrich Kaiser, 2011" />
      </Helmet>
    </Layout>
  )
}
